import React, { Component } from "react";
import Modal from "./components/Modal";
import { mainArt, projGenerationsBonus1, projGenerations2, projMIM, projSynthesis, projGenerations, projReminisce, projThree } from "./images/Art";
 
class Gallery extends Component {
    constructor(props) {
        super();
        this.state = {
            showModal: false,
            currentArt: "",
        };

        this.handler = this.closeModal.bind(this);
    }

    componentDidMount() {
        document.title = 'DESKTOPGENERATION::GALLERY';
    }

    openModal(inputArt) {
        this.setState({currentArt:inputArt});
        this.setState({showModal:true});
    }

    closeModal(event) {
        this.setState({showModal:false});
    }

    render() {
        return (
            <div className="contentInner">
                <Modal show={this.state.showModal} art={this.state.currentArt} handler={this.handler}/>
                <div className="contentMargin">
                    <img src={require('./images/assets/works.png')} alt="About desktopGeneration" className="pcLeftTitle" />
                    <div className="pcLeftMargin">
                        <h2>MAIN GALLERY:</h2>
                        <p>All of our stand-alone works.</p>
                        <div className="memberList">
                            {
                                mainArt.map(art => (
                                    <div key={art.id} className="memberBox">
                                        <div className="memberImage"><img src={require('./images/art/preview/' + art.id + '.png')} onClick={() => {this.openModal(art.id)}} alt={art.name + " preview"}></img></div>
                                        <div className="memberName"><p>{art.name}<br/><small>{art.artists}</small></p></div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="homeBarA" style={{marginTop:'0px', marginBottom:'40px'}}></div>
                        <h2>DESKTOP::GENERATIONS BONUS DISC 1:</h2>
                        <p>A bonus collection of wallpapers for christmas 2022.</p>
                        <div className="memberList">
                            {
                                projGenerationsBonus1.map(art => (
                                    <div key={art.id} className="memberBox">
                                        <div className="memberImage"><img src={require('./images/art/preview/' + art.id + '.png')} onClick={() => {this.openModal(art.id)}} alt={art.name + " preview"}></img></div>
                                        <div className="memberName"><p>{art.name}<br/><small>{art.artists}</small></p></div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="homeBarA" style={{marginTop:'0px', marginBottom:'40px'}}></div>
                        <h2>DESKTOP::GENERATIONS VOLUME 2:</h2>
                        <p>A celebratory wallpaper pack for 1 year of desktopGeneration.</p>
                        <div className="memberList">
                            {
                                projGenerations2.map(art => (
                                    <div key={art.id} className="memberBox">
                                        <div className="memberImage"><img src={require('./images/art/preview/' + art.id + '.png')} onClick={() => {this.openModal(art.id)}} alt={art.name + " preview"}></img></div>
                                        <div className="memberName"><p>{art.name}<br/><small>{art.artists}</small></p></div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="homeBarA" style={{marginTop:'0px', marginBottom:'40px'}}></div>
                        <h2>Metalheart Is Mobile:</h2>
                        <p>A collaborative mobile wallpaper pack between desktopGeneration, SourceNet, DYS and Hypertrance.</p>
                        <div className="memberList">
                            {
                                projMIM.map(art => (
                                    <div key={art.id} className="memberBox">
                                        <div className="memberImage"><img src={require('./images/art/preview/' + art.id + '.png')} onClick={() => {this.openModal(art.id)}} alt={art.name + " preview"}></img></div>
                                        <div className="memberName"><p>{art.name}<br/><small>{art.artists}</small></p></div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="homeBarA" style={{marginTop:'0px', marginBottom:'40px'}}></div>
                        <h2>.//SYNTHESIS:</h2>
                        <p>Work in progress...</p>
                        <div className="memberList">
                            {
                                projSynthesis.map(art => (
                                    <div key={art.id} className="memberBox">
                                        <div className="memberImage"><img src={require('./images/art/preview/' + art.id + '.png')} onClick={() => {this.openModal(art.id)}} alt={art.name + " preview"}></img></div>
                                        <div className="memberName"><p>{art.name}<br/><small>{art.artists}</small></p></div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="homeBarA" style={{marginTop:'0px', marginBottom:'40px'}}></div>
                        <h2>DESKTOP::GENERATIONS:</h2>
                        <p>A wallpaper pack for the modern computer user.</p>
                        <div className="memberList">
                            {
                                projGenerations.map(art => (
                                    <div key={art.id} className="memberBox">
                                        <div className="memberImage"><img src={require('./images/art/preview/' + art.id + '.png')} onClick={() => {this.openModal(art.id)}} alt={art.name + " preview"}></img></div>
                                        <div className="memberName"><p>{art.name}<br/><small>{art.artists}</small></p></div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="homeBarA" style={{marginTop:'0px', marginBottom:'40px'}}></div>
                        <h2>REMINISCE:</h2>
                        <p>A past you seek to recapture.</p>
                        <div className="memberList">
                            {
                                projReminisce.map(art => (
                                    <div key={art.id} className="memberBox">
                                        <div className="memberImage"><img src={require('./images/art/preview/' + art.id + '.png')} onClick={() => {this.openModal(art.id)}} alt={art.name + " preview"}></img></div>
                                        <div className="memberName"><p>{art.name}<br/><small>{art.artists}</small></p></div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="homeBarA" style={{marginTop:'0px', marginBottom:'40px'}}></div>
                        <h2>THREEBYTHREE:</h2>
                        <p>Welcome to a future beyond your understanding.</p>
                        <div className="memberList">
                            {
                                projThree.map(art => (
                                    <div key={art.id} className="memberBox">
                                        <div className="memberImage"><img src={require('./images/art/preview/' + art.id + '.png')} onClick={() => {this.openModal(art.id)}} alt={art.name + " preview"}></img></div>
                                        <div className="memberName"><p>{art.name}<br/><small>{art.artists}</small></p></div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="homeBarA" style={{marginTop:'0px'}}></div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Gallery;
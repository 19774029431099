//Main components
import React from "react";
import {useState} from 'react';
import './styles/App.css';
import { Routes, Route, NavLink } from "react-router-dom";

//Pages
import Home from "./Home";
import Gallery from "./Gallery";
import About from "./About";
import Shop from "./Shop";
import Contact from "./Contact";

//Icons & Images
import { default as Arrow } from './images/assets/arrow.svg';
import { default as Discord } from './images/assets/ico_discord.svg';
import { default as LinkedIn } from './images/assets/ico_linkedin.svg';
import { default as Behance } from './images/assets/ico_behance.svg';
import { default as Twitter } from './images/assets/ico_twitter.svg';
import { default as Tumblr } from './images/assets/ico_tumblr.svg';
import { default as Instagram } from './images/assets/ico_instagram.svg';
import { default as ItchIO } from './images/assets/ico_itchio.svg';

function App() {
    const [menuActive, setMenuActive] = useState(false);

    const toggleMenu = event => {
        setMenuActive(current => !current);
    };
    
    const closeMenu = event => {
        setMenuActive(false);
    };

    const current = new Date();
    const date = `CURRENT.DATE.${current.getDate()}.${current.getMonth()+1}.${current.getFullYear()}.TIME.${current.getHours()}.${current.getMinutes()}.${current.getSeconds()}.LOCALTIME`;

    return (
        <div className="mainContainer">
            <div className={menuActive ? 'activeMenuContainer' : 'menuContainer'}>
                <div className="menuBackground">
                    <nav className="menu">
                        <NavLink end to="/" className="nav-link" onClick={closeMenu}>
                            <img src={require('./images/assets/deskGenHeader.png')} alt="desktopGeneration logo" style={{width:'100%',}} />
                        </NavLink>
                        <p style={{textAlign:'center', fontWeight:'bold'}}>A future beyond your understanding.</p>
                        <hr style={{borderWidth: '2px 0px 0px 0px', borderStyle:'dashed', borderColor: 'black', marginTop:'25px', marginBottom: '20px',}} />
                        <ul>
                            <NavLink end to="/" className={({ isActive }) => "nav-link" + (isActive ? " activeMenu" : "")} onClick={closeMenu}>
                                <li>HOME<span style={{float:'right'}}>.01<img src={Arrow} alt="" className="arrowIcon" /></span></li>
                            </NavLink>
                            <NavLink end to="/gallery" className={({ isActive }) => "nav-link" + (isActive ? " activeMenu" : "")} onClick={closeMenu}>
                                <li>GALLERY<span style={{float:'right'}}>.02<img src={Arrow} alt="" className="arrowIcon" /></span></li>
                            </NavLink>
                            <NavLink end to="/shop" className={({ isActive }) => "nav-link" + (isActive ? " activeMenu" : "")} onClick={closeMenu}>
                                <li>SHOP<span style={{float:'right'}}>.03<img src={Arrow} alt="" className="arrowIcon" /></span></li>
                            </NavLink>
                            <NavLink end to="/about"  className={({ isActive }) => "nav-link" + (isActive ? " activeMenu" : "")} onClick={closeMenu}>
                                <li>ABOUT<span style={{float:'right'}}>.04<img src={Arrow} alt="" className="arrowIcon" /></span></li>
                            </NavLink>
                            <NavLink end to="/contact"  className={({ isActive }) => "nav-link" + (isActive ? " activeMenu" : "")} onClick={closeMenu}>
                                <li>CONTACT<span style={{float:'right'}}>.05<img src={Arrow} alt="" className="arrowIcon" /></span></li>
                            </NavLink>
                        </ul>
                        <hr style={{borderWidth: '2px 0px 0px 0px', borderStyle:'dashed', borderColor: 'black', marginTop:'20px', marginBottom: '20px',}} />
                        <div>
                            <p>&#8989;&nbsp;&nbsp;&nbsp;&#8989;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#8989;</p>
                            <p>&#8989;&nbsp;&nbsp;&nbsp;&#8989;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#8989;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>&#11820;</b>&nbsp;<b>&#11820;</b></p>
                            <p><small>C000000/#FFFFFF</small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>&#11820;</b>&nbsp;<b>&#11820;</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>&#11820;</b>&nbsp;<b>&#11820;</b></p>
                            <p style={{color:'#ff2189'}}><small>COLORSCHEME<b>&#11820;</b></small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>.MAGENTA</small></p>
                            <p>&#8989;&nbsp;&nbsp;&nbsp;&#8989;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#8989;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>&#11820;</b>&nbsp;<b>&#11820;</b></p>
                        </div>
                        <img src={require('./images/assets/deskCut.png')} alt="desktopGeneration logo cut" style={{width:'100%', marginBottom:'6px'}} />
                        <div className="homeBarA" style={{marginTop:'8px'}}></div>
                        <div className="homeBarA"></div>
                        <div style={{marginTop:'30px', marginBottom:'15px', fontWeight:'bold'}}>
                            <p><small>{date}</small><span style={{float:'right'}}><b>&#11820;</b></span></p>
                            <p>
                                <small>
                                    WEBSITE ESTABLISHED BY DESKTOPGENERATION, LLC.<br/>
                                    ART AND DESIGN. SITE POWERED BY REACT &amp; PASSION.<br/>
                                    COPYRIGHT &copy; 2021 - {new Date().getFullYear()} ALL RIGHTS RESERVED.
                                </small>
                                <span style={{float:'right'}}><b>&#11820;</b></span>
                            </p>
                            <p>
                                <small>
                                    WEBSITE CONCEPT BY YAZAN A.<br/>
                                    WEBSITE CREATED BY WSX.
                                </small>
                                <span style={{float:'right'}}><b>&#11820;</b></span>
                            </p>
                            <div className="homeBarB"><div></div></div>
                        </div>
                        <div className="socialButtons">
                            <a href="https://discord.com/invite/EDsQHK38Wp" target="_blank" rel="noreferrer"><img src={Discord} alt="Discord link"></img></a>
                            <a href="https://www.linkedin.com/company/desktopgeneration/" target="_blank" rel="noreferrer"><img src={LinkedIn} alt="LinkedIn link"></img></a>
                            <a href="https://www.behance.net/desktopGeneration" target="_blank" rel="noreferrer"><img src={Behance} alt="Behance link"></img></a>
                            <a href="https://twitter.com/dsktpGeneration" target="_blank" rel="noreferrer"><img src={Twitter} alt="Twitter link"></img></a>
                            <a href="https://dsktpgeneration.tumblr.com/" target="_blank" rel="noreferrer"><img src={Tumblr} alt="Tumblr link"></img></a>
                            <a href="https://www.instagram.com/dsktpgeneration/" target="_blank" rel="noreferrer"><img src={Instagram} alt="Instagram link"></img></a>
                            <a href="https://desktopgeneration.itch.io/" target="_blank" rel="noreferrer"><img src={ItchIO} alt="Itch.io link"></img></a>
                        </div>
                    </nav>
                </div>
            </div>
            <div className={menuActive ? 'activeContent' : 'content'}>
                <div className={menuActive ? 'menuActive menuButton' : 'menuButton'} onClick={toggleMenu}><img src={require('./images/assets/menu.png')} alt="menu button" style={{width:'42px', height:'42px'}} /></div>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/shop" element={<Shop />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
            </div>
        </div>
    );
}

export default App;
import React, { Component } from "react";
import { mainProducts, memberProducts } from "./images/Products";
 
class Shop extends Component {
    constructor(props) {
        super();
        this.state = {
            showModal: false,
            currentProduct: "",
        };

        this.handler = this.closeModal.bind(this);
    }

    componentDidMount() {
        document.title = 'DESKTOPGENERATION::SHOP';
    }

    openModal(inputArt) {
        this.setState({currentProduct:inputArt});
        this.setState({showModal:true});
    }

    closeModal(event) {
        this.setState({showModal:false});
    }

    render() {
        return (
            <div className="contentInner">
                <div className="contentMargin">
                    <img src={require('./images/assets/store.png')} alt="About desktopGeneration" className="pcLeftTitle" />
                    <div className="pcLeftMargin">
                        <h2>DESKTOPGENERATION MERCH & DOWNLOADS:</h2>
                        <p>desktopGeneration merch for all your needs.</p>
                        <div className="memberList">
                            {
                                mainProducts.map(products => (
                                    <div key={products.id} className="productBox">
                                        <div className="memberImage"><a href={products.link} target="_blank" rel="noreferrer"><img src={require('./images/shop/' + products.id + '.png')} alt={products.name + " preview"}></img></a></div>
                                        <div className="memberName"><p>{products.name}<br/><small><i>{products.type} - by {products.artists}</i></small><br/><small><b>{products.price}</b></small></p></div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="homeBarA" style={{marginTop:'0px', marginBottom:'40px'}}></div>
                        <h2>ARTIST MERCH & DOWNLOADS:</h2>
                        <p>Merch made by our artists individually.</p>
                        <div className="memberList">
                            {
                                memberProducts.map(products => (
                                    <div key={products.id} className="productBox">
                                        <div className="memberImage"><a href={products.link} target="_blank" rel="noreferrer"><img src={require('./images/shop/' + products.id + '.png')} alt={products.name + " preview"}></img></a></div>
                                        <div className="memberName"><p>{products.name}<br/><small><i>{products.type} - by {products.artists}</i></small><br/><small><b>{products.price}</b></small></p></div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="homeBarA" style={{marginTop:'0px'}}></div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Shop;
import React, { Component } from "react";
import { Link } from "react-router-dom";
 
class About extends Component {
    componentDidMount() {
        document.title = 'DESKTOPGENERATION::ABOUT';
    }

    render() {
        return (
            <div className="contentInner">
                <div className="contentMargin">
                    <div className="pcLeftMargin">
                        <h2>ABOUT DESKTOPGENERATION:</h2>
                        <p>
                            <b>.:</b>We are a collaborative art & design firm from a future beyond your understanding.<br/>
                            <br/>
                            <b>.:</b>Our goal as artists is to forge a new future for digital art. We’ve spent the majority of our professional careers coming to the conclusion that we work better together than apart, and seek to unify our vastly different artistic methods and styles to create works of art that look unlike anything the world has seen before. While our roots go back decades, our <Link to="/gallery">results</Link> speak for themselves.<br/>
                            <br/>
                            <i>Like what you see? You should <Link to="/contact">get in touch</Link>.</i>
                        </p>
                        <div className="homeBarA" style={{marginBottom:'60px'}}></div>
                    </div>
                    <img src={require('./images/assets/about.png')} alt="About desktopGeneration" className="pcLeftTitle" />
                    <div className="pcLeftMargin">
                        <h2>CO-FOUNDERS:</h2>
                        <div className="memberList">
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/BennettBlogs" target="_blank" rel="noreferrer"><img src={require('./images/members/andrew.png')} alt="ANDREW BENNETT logo"></img></a></div>
                                <div className="memberName"><p>ANDREW B.<br/><small><a href="https://twitter.com/BennettBlogs" target="_blank" rel="noreferrer" className="memberLink">@BENNETBLOGS</a></small></p></div>
                            </div>
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/aetherphase" target="_blank" rel="noreferrer"><img src={require('./images/members/fnyx.png')} alt="FNYX logo"></img></a></div>
                                <div className="memberName"><p>CASS<br/><small><a href="https://twitter.com/aetherphase" target="_blank" rel="noreferrer" className="memberLink">@AETHERPHASE</a></small></p></div>
                            </div>
                        </div>
                        <h2>CURRENT MEMBERS:</h2>
                        <div className="memberList">
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/aletiune" target="_blank" rel="noreferrer"><img src={require('./images/members/cherry.png')} alt="CHERRY logo"></img></a></div>
                                <div className="memberName"><p>CHERRY<br/><small><a href="https://twitter.com/aletiune" target="_blank" rel="noreferrer" className="memberLink">@ALETIUNE</a></small></p></div>
                            </div>
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/chocographix" target="_blank" rel="noreferrer"><img src={require('./images/members/choco.png')} alt="CHOCO logo"></img></a></div>
                                <div className="memberName"><p>CHOCO<br/><small><a href="https://twitter.com/chocographix" target="_blank" rel="noreferrer" className="memberLink">@CHOCOGRAPHIX</a></small></p></div>
                            </div>
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/dove_xiii" target="_blank" rel="noreferrer"><img src={require('./images/members/dove.png')} alt="DOVE logo"></img></a></div>
                                <div className="memberName"><p>DOVE<br/><small><a href="https://twitter.com/dove_xiii" target="_blank" rel="noreferrer" className="memberLink">@DOVE_XIII</a></small></p></div>
                            </div>
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/officialDruu" target="_blank" rel="noreferrer"><img src={require('./images/members/druu.png')} alt="DRUU logo"></img></a></div>
                                <div className="memberName"><p>DRUU<br/><small><a href="https://twitter.com/officialDruu" target="_blank" rel="noreferrer" className="memberLink">@OFFICIALDRUU</a></small></p></div>
                            </div>
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/SpiderQueenPC" target="_blank" rel="noreferrer"><img src={require('./images/members/grimm.png')} alt="GRIMM logo"></img></a></div>
                                <div className="memberName"><p>GRIMM<br/><small><a href="https://twitter.com/SpiderQueenPC" target="_blank" rel="noreferrer" className="memberLink">@SPIDERQUEENPC</a></small></p></div>
                            </div>
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/takada_dot" target="_blank" rel="noreferrer"><img src={require('./images/members/takada.png')} alt="TAKADA logo"></img></a></div>
                                <div className="memberName"><p>TAKADA<br/><small><a href="https://twitter.com/takada_dot" target="_blank" rel="noreferrer" className="memberLink">@TAKADA_DOT</a></small></p></div>
                            </div>
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/halfprism_" target="_blank" rel="noreferrer"><img src={require('./images/members/halfprism.png')} alt="HALFPRISM logo"></img></a></div>
                                <div className="memberName"><p>HALFPRISM<br/><small><a href="https://twitter.com/halfprism_" target="_blank" rel="noreferrer" className="memberLink">@HALFPRISM_</a></small></p></div>
                            </div>
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/helvatten" target="_blank" rel="noreferrer"><img src={require('./images/members/helvatten.png')} alt="HELVATTEN logo"></img></a></div>
                                <div className="memberName"><p>HELVATTEN<br/><small><a href="https://twitter.com/helvatten" target="_blank" rel="noreferrer" className="memberLink">@HELVATTEN</a></small></p></div>
                            </div>
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/inlinesix_" target="_blank" rel="noreferrer"><img src={require('./images/members/inlinesix.png')} alt="INLINESIX logo"></img></a></div>
                                <div className="memberName"><p>INLINESIX<br/><small><a href="https://twitter.com/inlinesix_" target="_blank" rel="noreferrer" className="memberLink">@INLINESIX_</a></small></p></div>
                            </div>
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/jackflash776" target="_blank" rel="noreferrer"><img src={require('./images/members/jack.png')} alt="JACK logo"></img></a></div>
                                <div className="memberName"><p>JACK<br/><small><a href="https://twitter.com/jackflash776" target="_blank" rel="noreferrer" className="memberLink">@JACKFLASH776</a></small></p></div>
                            </div>
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/mikyoku_mk2" target="_blank" rel="noreferrer"><img src={require('./images/members/mikyoku.png')} alt="御曲 MIKYOKU logo"></img></a></div>
                                <div className="memberName"><p>御曲 MIKYOKU<br/><small><a href="https://twitter.com/mikyoku_mk2" target="_blank" rel="noreferrer" className="memberLink">@MIKYOKU_MK2</a></small></p></div>
                            </div>
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/nninety_" target="_blank" rel="noreferrer"><img src={require('./images/members/nninety.png')} alt="NNINETY logo"></img></a></div>
                                <div className="memberName"><p>NNINETY<br/><small><a href="https://twitter.com/nninety_" target="_blank" rel="noreferrer" className="memberLink">@NNINETY_</a></small></p></div>
                            </div>
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/n0isivy" target="_blank" rel="noreferrer"><img src={require('./images/members/noisivy.png')} alt="NOISIVY logo"></img></a></div>
                                <div className="memberName"><p>NOISIVY<br/><small><a href="https://twitter.com/n0isivy" target="_blank" rel="noreferrer" className="memberLink">@N0ISIVY</a></small></p></div>
                            </div>
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/scidsgn" target="_blank" rel="noreferrer"><img src={require('./images/members/sci.png')} alt="SCI logo"></img></a></div>
                                <div className="memberName"><p>SCI<br/><small><a href="https://twitter.com/scidsgn" target="_blank" rel="noreferrer" className="memberLink">@SCIDSGN</a></small></p></div>
                            </div>
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/Wsxmoe" target="_blank" rel="noreferrer"><img src={require('./images/members/wsx.png')} alt="WSX logo"></img></a></div>
                                <div className="memberName"><p>WSX<br/><small><a href="https://twitter.com/Wsxmoe" target="_blank" rel="noreferrer" className="memberLink">@WSXMOE</a></small></p></div>
                            </div>
                            <div className="memberBox">
                                <div className="memberImage"><a href="https://twitter.com/yazanoo16" target="_blank" rel="noreferrer"><img src={require('./images/members/yazan.png')} alt="YAZAN A. logo"></img></a></div>
                                <div className="memberName"><p>YAZAN A.<br/><small><a href="https://twitter.com/yazanoo16" target="_blank" rel="noreferrer" className="memberLink">@YAZANOO16</a></small></p></div>
                            </div>
                        </div>
                        <div className="homeBarA" style={{marginTop:'0px'}}></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;
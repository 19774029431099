import React, { Component } from "react";
import { Link } from "react-router-dom";
 
class Contact extends Component {
    componentDidMount() {
        document.title = 'DESKTOPGENERATION::CONTACT';
    }

    render() {
        return (
            <div className="contentInner">
                <div className="contentMargin">
                    <img src={require('./images/assets/contact.png')} alt="About desktopGeneration" className="pcLeftTitle" />
                    <div className="pcLeftMargin">
                        <h2>GET IN TOUCH:</h2>
                        <p>There’s a few ways to reach out to desktopGeneration.</p>
                        <img src={require('./images/assets/connectionheader.png')} alt="GETCONNECTED art cut" style={{width:'100%', height:'auto'}} />
                        <small>GET CONNECTED - Sci</small>
                        <p>If you’d like to reach us directly, there are two options:</p>
                        <p><b>.:A</b>&nbsp;You can email us via <a href="mailto:contact@desktopgeneration.com">contact@desktopgeneration.com</a></p>
                        <p><b>.:B</b>&nbsp;You can send a direct message to our Twitter account <a href="https://twitter.com/dsktpGeneration" target="_blank" rel="noreferrer">@dsktpGeneration</a>. While we do use other forms of social media, this is the primary account we check for direct messages.</p> 
                        <p>Additionally, you can contact the individual members of desktopGeneration via Twitter or their preferred contacting methods - their accounts are all listed on our <Link to="/about">about page</Link>.</p>
                        <div className="homeBarA" style={{marginTop:'0px', marginBottom:'40px'}}></div>
                        <h2>HIRING US:</h2>
                        <p>desktopGeneration is currently open and available for a multitude of work, ranging from long-term contract work to one-off commissions. To discuss rates, contact us via one of the methods listed above. <b>Please note that we reserve the right to decline any work we deem inappropriate, such as not-safe-for-work (NSFW) content or anything pertaining to cryptocurrency, such as non-fungible tokens ("NFTs"/"digital collectibles").</b></p>
                        <p><small>desktopGeneration, LLC is a registered firm in the state of Ohio, USA.</small></p>
                        <div className="homeBarA" style={{marginTop:'0px'}}></div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Contact;
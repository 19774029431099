import React from 'react';
import { Fade } from 'react-slideshow-image';

const FadeExample = () => {
  return (
        <div className="slide-container">
            <Fade arrows={false} canSwipe={false} pauseOnHover={false} duration={5000} transitionDuration={1500}>
                <div className="each-fade">
                    <div>
                        <div className='coverCredits'>
                            <p className='coverCreditsLarge'>FIREFLOWER</p>
                            <p className='coverCreditsSmall'>Andrew B.</p>
                        </div>
                        <img src={require('../images/art/slideshow/slide_1.webp')} alt="" />
                    </div>
                </div>
                <div className="each-fade">
                    <div>
                        <div className='coverCredits'>
                            <p className='coverCreditsLarge'>ABSOLUTE NEON</p>
                            <p className='coverCreditsSmall'>Choco & Jack</p>
                        </div>
                        <img src={require('../images/art/slideshow/slide_2.webp')} alt="" />
                    </div>
                </div>
                <div className="each-fade">
                    <div>
                        <div className='coverCredits'>
                            <p className='coverCreditsLarge'>PURIFICO2</p>
                            <p className='coverCreditsSmall'>Andrew B., Yazan A. & Wsx</p>
                        </div>
                        <img src={require('../images/art/slideshow/slide_3.webp')} alt="" />
                    </div>
                </div>
                <div className="each-fade">
                    <div>
                        <div className='coverCredits'>
                            <p className='coverCreditsLarge'>OUTPACE</p>
                            <p className='coverCreditsSmall'>halfprism & Andrew B.</p>
                        </div>
                        <img src={require('../images/art/slideshow/slide_4.webp')} alt="" />
                    </div>
                </div>
                <div className="each-fade">
                    <div>
                        <div className='coverCredits'>
                            <p className='coverCreditsLarge'>ORIGINS OF NATURE</p>
                            <p className='coverCreditsSmall'>Cherry</p>
                        </div>
                        <img src={require('../images/art/slideshow/slide_5.webp')} alt="" />
                    </div>
                </div>
                <div className="each-fade">
                    <div>
                        <div className='coverCredits'>
                            <p className='coverCreditsLarge'>PSYCHOTICSTREAM’06</p>
                            <p className='coverCreditsSmall'>Jack</p>
                        </div>
                        <img src={require('../images/art/slideshow/slide_6.webp')} alt="" />
                    </div>
                </div>
            </Fade>
        </div>
    );
};

export default FadeExample;
import React from 'react';

const Modal = props => {
    if(!props.show) {
        return null
    }

    return(
        <div className="modal" onClick={props.handler}>
            <div className="modalContent" onClick={e => e.stopPropagation()}>
                <div className="modalBody">
                    <button onClick={props.handler}>X</button>
                    <img src={require('../images/art/gallery/' + props.art + '.png')} alt={props.art} />
                </div>
            </div>
        </div>
    )
}

export default Modal;
import React, { Component } from "react";
import HomeSlideShow from './components/HomeSlideShow';
import 'react-slideshow-image/dist/styles.css'
 
class Home extends Component {
    componentDidMount() {
        document.title = 'DESKTOPGENERATION::HOME';
    }

    render() {
        return (
            <div>
                <HomeSlideShow />
            </div>
        );
    }
}
 
export default Home;
export const mainProducts=[
    {
        "id": "abstractysm",
        "name": "Abstractysm",
        "price": "Free",
        "type": "Wallpaper Pack",
        "artists": "Yazan A.",
        "link": "https://desktopgeneration.itch.io/abstractysm",
    },
    {
        "id": "desktopgenerations_bonus",
        "name": "DESKTOP::GENERATIONS BONUS DISC 1",
        "price": "Free",
        "type": "Wallpaper Pack",
        "artists": "Andrew B.",
        "link": "https://desktopgeneration.itch.io/desktopgenerations-bonus-disc-1",
    },
    {
        "id": "desktopgenerations_volume_2",
        "name": "DESKTOP::GENERATIONS VOLUME 2",
        "price": "Free",
        "type": "Wallpaper Pack",
        "artists": "desktopGeneration",
        "link": "https://desktopgeneration.itch.io/desktopgenerations2",
    },
    {
        "id": "mim",
        "name": "METALHEART IS MOBILE",
        "price": "Free",
        "type": "Wallpaper Pack",
        "artists": "desktopGeneration",
        "link": "https://drive.google.com/drive/folders/1HEZwVzZd8qGAQC7Vo7lrLBT4WTVlBpvH",
    },
    {
        "id": "desktopgenerations",
        "name": "DESKTOP::GENERATIONS",
        "price": "Free",
        "type": "Wallpaper Pack",
        "artists": "desktopGeneration",
        "link": "https://desktopgeneration.itch.io/desktopgenerations",
    },
];

export const memberProducts=[
    {
        "id": "cyberworks_vol_2",
        "name": "Cyberworks - Volume 2",
        "price": "$15",
        "type": "Artbook",
        "artists": "Andrew B.",
        "link": "https://www.blurb.com/b/11526459-cyberworks-volume-2",
    },
    {
        "id": "cyberworks_vol_1",
        "name": "Cyberworks - Volume 1",
        "price": "$15",
        "type": "Artbook",
        "artists": "Andrew B.",
        "link": "https://www.blurb.com/b/11134495-cyberworks-volume-1",
    },
    {
        "id": "cherry_inprnt",
        "name": "Cherry Art Prints",
        "price": "$15-$40",
        "type": "Print/Poster",
        "artists": "Cherry",
        "link": "https://www.inprnt.com/gallery/aletiune/",
    },
    {
        "id": "halfprism_inprnt",
        "name": "Halfprism Art Prints",
        "price": "$15-$20",
        "type": "Print/Poster",
        "artists": "halfprism",
        "link": "https://www.inprnt.com/gallery/halfprism/",
    },
    {
        "id": "jack_inprnt",
        "name": "Jack Art Prints",
        "price": "$20-$40",
        "type": "Print/Poster",
        "artists": "Jack",
        "link": "https://www.inprnt.com/gallery/jackflash776/",
    },
    {
        "id": "andrew_inprnt",
        "name": "Andrew B. Art Prints",
        "price": "$18-$23",
        "type": "Print/Poster",
        "artists": "Andrew B.",
        "link": "https://www.inprnt.com/gallery/bennettblogs/",
    },
    {
        "id": "resource_futura_1",
        "name": "resource::futura - Volume 1",
        "price": "Free",
        "type": "Art Resources",
        "artists": "Andrew B.",
        "link": "https://drive.google.com/file/d/1YJTWDinG1VSIMjIMKB-icj3liwTlRBBW/view",
    },
    {
        "id": "periodic_table_project_files",
        "name": "Periodic Table Project Files",
        "price": "$15",
        "type": "Art Resources",
        "artists": "Sci",
        "link": "https://sci.gumroad.com/l/iEyFm",
    },
    {
        "id": "periodic_table_graphics",
        "name": "Periodic Table Graphics",
        "price": "$25",
        "type": "Wallpaper Pack",
        "artists": "Sci",
        "link": "https://sci.gumroad.com/l/LATQc",
    },
    {
        "id": "granite",
        "name": "GRANITE",
        "price": "$7.50",
        "type": "Font",
        "artists": "Sci",
        "link": "https://sci.gumroad.com/l/FtJgX",
    },
    {
        "id": "zerobrute",
        "name": "ZEROBRUTE",
        "price": "$10",
        "type": "Font",
        "artists": "Sci",
        "link": "https://sci.gumroad.com/l/lYWkc",
    },
    {
        "id": "scinodes_2",
        "name": "SCINODES 2 for Blender",
        "price": "Free",
        "type": "Art Resources",
        "artists": "Sci",
        "link": "https://sci.gumroad.com/l/rRkUh",
    },
    {
        "id": "liquid_glass_pro",
        "name": "Liquid Glass Pro",
        "price": "Free - $5",
        "type": "Wallpaper Pack",
        "artists": "Sci",
        "link": "https://sci.gumroad.com/l/PqYGA",
    },
    {
        "id": "sci_valley",
        "name": "Sci Valley",
        "price": "Free",
        "type": "Wallpaper Pack",
        "artists": "Sci",
        "link": "https://sci.gumroad.com/l/xOZgCr",
    },
    {
        "id": "one_year_anniversary_book",
        "name": "One Year Anniversary [Digital Only]",
        "price": "$15",
        "type": "Artbook",
        "artists": "noisivy",
        "link": "https://payhip.com/b/1aCc3",
    },
    {
        "id": "astral_collection_1",
        "name": "Astral Collection #1",
        "price": "$10",
        "type": "Wallpaper pack",
        "artists": "noisivy",
        "link": "https://payhip.com/b/4tOjH",
    },
    {
        "id": "lunar",
        "name": "Lunar",
        "price": "$6",
        "type": "Font",
        "artists": "noisivy",
        "link": "https://payhip.com/b/I5BG",
    },
    {
        "id": "ultradigital",
        "name": "Ultradigital",
        "price": "$6",
        "type": "Font",
        "artists": "noisivy",
        "link": "https://payhip.com/b/2TtI",
    },
    {
        "id": "fioir_mascot_design",
        "name": "Fioir: Mascot Design",
        "price": "$6",
        "type": "Art Resources",
        "artists": "noisivy",
        "link": "https://payhip.com/b/qXmx",
    },
    {
        "id": "absinthe_2000",
        "name": "Absinthe 2000",
        "price": "$10",
        "type": "Font",
        "artists": "noisivy",
        "link": "https://payhip.com/b/H5Jj",
    },
    {
        "id": "astra",
        "name": "Astra",
        "price": "$6",
        "type": "Font",
        "artists": "noisivy",
        "link": "https://payhip.com/b/EtZR",
    },
];
export const mainArt=[
    {
        "id": "kontravene",
        "name": ".KONTRAVENE",
        "artists": "Yazan A. & Cass",
    },
    {
        "id": "absolute_neon",
        "name": "ABSOLUTE NEON",
        "artists": "Choco & Jack",
    },
    {
        "id": "welcome_to_the_social",
        "name": "WELCOME TO THE ::SOCIAL",
        "artists": "Andrew B. & nFinite",
    },
    {
        "id": "microcosm",
        "name": "MICROCOSM",
        "artists": "Choco & halfprism",
    },
    {
        "id": "trinitysm",
        "name": "TRINITYSM",
        "artists": "Mikyoku & nFinite & Yazan A.",
    },
    {
        "id": "lunar_flare",
        "name": "LUNAR FLARE",
        "artists": "Jack & halfprism",
    },
    {
        "id": "cyberage",
        "name": "CYBERAGE",
        "artists": "nFinite & Yazan A.",
    },
    {
        "id": "purifico2",
        "name": "PURIFICO2",
        "artists": "Andrew B., Yazan A. & Wsx",
    },
    {
        "id": "commiseration",
        "name": "COMMISERATION",
        "artists": "Grimm & Sci",
    },
    {
        "id": "hprism_t800",
        "name": "HPRISM T800",
        "artists": "noisivy & halfprism",
    },
    {
        "id": "aerowave",
        "name": "AEROWAVE",
        "artists": "nFinite & Wsx",
    },
    {
        "id": "corporate_classic",
        "name": "CORPORATE CLASSIC",
        "artists": "Andrew B., Jackflash, halfprism, Fnyx",
    },
    {
        "id": "infinity",
        "name": "INFINITY",
        "artists": "nFinite & Wsx",
    },
    {
        "id": "crystal",
        "name": "CRYSTAL",
        "artists": "halfprism & noisivy",
    },
    {
        "id": "construct_2",
        "name": "CONSTRUCT 2",
        "artists": "halfprism & Andrew B.",
    },
    {
        "id": "vibrant_fire",
        "name": "VIBRANT FIRE",
        "artists": "Andrew B., Grimm & Yazan A.",
    },
    {
        "id": "slice",
        "name": "SLICE",
        "artists": "nFinite & Andrew B.",
    },
    {
        "id": "re_define_industry",
        "name": "RE:DEFINE.INDUSTRY",
        "artists": "Andrew B. & nFinite",
    },
    {
        "id": "amense",
        "name": "AMENSE",
        "artists": "Yazan A. & nFinite",
    },
    {
        "id": "konsequence",
        "name": "KONSEQUENCE",
        "artists": "Yazan A. & nFinite",
    },
    {
        "id": "outpace",
        "name": "OUTPACE",
        "artists": "halfprism & Andrew B.",
    },
    {
        "id": "flight_of_the_effervescent",
        "name": "FLIGHT OF THE EFFERVESCENT",
        "artists": "Grimm & Jack",
    },
    {
        "id": "feel_the_light",
        "name": "FEEL THE LIGHT",
        "artists": "Grimm & halfprism",
    },
    {
        "id": "konquer",
        "name": "KONQUER",
        "artists": "Yazan A. & Andrew B.",
    },
    {
        "id": "apathum",
        "name": "APATHUM",
        "artists": "Yazan A. & Andrew B.",
    },
    {
        "id": "physica",
        "name": "PHYSICA",
        "artists": "Cherry & Andrew B.",
    },
    {
        "id": "judgement",
        "name": "JUDGEMENT",
        "artists": "Grimm & Wsx",
    },
    {
        "id": "construct_eternal",
        "name": "CONSTRUCT[ETERNAL]",
        "artists": "Jack & Andrew B.",
    },
    {
        "id": "it_all_returns_to_your_heart",
        "name": "IT ALL RETURNS TO YOUR HEART",
        "artists": "Jack & Andrew B.",
    },
    {
        "id": "solace85",
        "name": "SOLACE::85",
        "artists": "halfprism & Andrew B.",
    },
    {
        "id": "daega2988",
        "name": "DAEGA 2988",
        "artists": "halfprism, Andrew B. & Yazan A.",
    },
    {
        "id": "undefined",
        "name": "UNDEFINED",
        "artists": "Cherry & Andrew B.",
    },
    {
        "id": "northerlies",
        "name": "NORTHERLIES",
        "artists": "Cass, Andrew B. & Sci",
    },
    {
        "id": "stand_still",
        "name": "STAND:STILL",
        "artists": "halfprism & Andrew B.",
    },
    {
        "id": "mirror_world",
        "name": "MIRROR WORLD",
        "artists": "halfprism, Andrew B. & Sci",
    },
    {
        "id": "gateway_zone_unreality",
        "name": "GATEWAY: ZONE_UNREALITY",
        "artists": "halfprism & Sci",
    },
    {
        "id": "xiu",
        "name": "XIU",
        "artists": "nninety & Andrew B.",
    },
    {
        "id": "our_understanding",
        "name": "OUR UNDERSTANDING",
        "artists": "Jack & Andrew B.",
    },
    {
        "id": "material_re_initialisation",
        "name": "MATERIAL.RE: INITIALISATION",
        "artists": "Jack & Wsx",
    },
    {
        "id": "cyberspacefragments",
        "name": "CYBERSPACE FRAGMENTS",
        "artists": "Andrew B. & Wsx",
    },
    {
        "id": "elementalsystem",
        "name": "ELEMENTALSYSTEM",
        "artists": "Yazan A. & Andrew B.",
    },
    {
        "id": "aquaheart",
        "name": "AQUAHEART",
        "artists": "Andrew B. & Grimm",
    },
    {
        "id": "edgecut",
        "name": "EDGECUT",
        "artists": "Yazan A. & Andrew B.",
    },
    {
        "id": "juturna",
        "name": "JUTURNA",
        "artists": "Andrew B., Cass & Sangrakkorrakkor",
    },
    {
        "id": "terraflare",
        "name": "TERRAFLARE",
        "artists": "Varse & Andrew B.",
    },
    {
        "id": "radiant_flame",
        "name": "RADIANT FLAME",
        "artists": "Andrew B. & Grimm",
    },
    {
        "id": "false_mirage",
        "name": "FALSE MIRAGE",
        "artists": "Andrew B. & Cherry",
    },
    {
        "id": "minute_to_midnight",
        "name": "MINUTE TO MIDNIGHT",
        "artists": "Andrew B. & Cherry",
    },
    {
        "id": "purifico",
        "name": "PURIFICO",
        "artists": "Andrew B. & Cherry",
    },
];

export const projGenerationsBonus1=[
    {
        "id": "fireflower",
        "name": "FIREFLOWER",
        "artists": "Andrew B.",
    },
    {
        "id": "intradeluge",
        "name": "INTRADELUGE",
        "artists": "Andrew B.",
    },
    {
        "id": "project_208",
        "name": "PROJECT 208",
        "artists": "Andrew B.",
    },
    {
        "id": "regarding22",
        "name": "REGARDING22",
        "artists": "Andrew B.",
    },
];

export const projGenerations2=[
    {
        "id": "amberesce",
        "name": "AMBERESCE",
        "artists": "halfprism",
    },
    {
        "id": "atrium",
        "name": "ATRIUM",
        "artists": "halfprism",
    },
    {
        "id": "avalon_2",
        "name": "AVALON 2",
        "artists": "Andrew B.",
    },
    {
        "id": "chronography",
        "name": "CHRONOGRAPHY",
        "artists": "noisivy",
    },
    {
        "id": "colorvision",
        "name": "COLORVISION",
        "artists": "Sci",
    },
    {
        "id": "ego_death",
        "name": "EGO DEATH",
        "artists": "Jack & Grimm",
    },
    {
        "id": "elysian",
        "name": "ELYSIAN",
        "artists": "Jack & Wsx",
    },
    {
        "id": "etcetera_dark",
        "name": "ETCETERA DARK",
        "artists": "Yazan A.",
    },
    {
        "id": "etcetera_light",
        "name": "ETCETERA LIGHT",
        "artists": "Yazan A.",
    },
    {
        "id": "frostbite",
        "name": "FROSTBITE",
        "artists": "Sci",
    },
    {
        "id": "g_works",
        "name": "G-WORKS",
        "artists": "Andrew B. & nFinite",
    },
    {
        "id": "gladiator",
        "name": "GLADIATOR",
        "artists": "Jack",
    },
    {
        "id": "gxsc_1",
        "name": "GXSC 1",
        "artists": "nFinite",
    },
    {
        "id": "gxsc_2",
        "name": "GXSC 2",
        "artists": "nFinite",
    },
    {
        "id": "gxsc_3",
        "name": "GXSC 3",
        "artists": "nFinite",
    },
    {
        "id": "gxsc_4",
        "name": "GXSC 4",
        "artists": "nFinite",
    },
    {
        "id": "hyperglow",
        "name": "HYPERGLOW",
        "artists": "Andrew B. & Yazan A.",
    },
    {
        "id": "hyperschism",
        "name": "HYPERSCHISM",
        "artists": "Jack",
    },
    {
        "id": "industry",
        "name": "INDUSTRY",
        "artists": "Andrew B.",
    },
    {
        "id": "irrglr",
        "name": "IRRGLR",
        "artists": "Yazan A.",
    },
    {
        "id": "mantra",
        "name": "MANTRA",
        "artists": "Jack",
    },
    {
        "id": "antique",
        "name": "MULT1COLOR - ANTIQUE",
        "artists": "nFinite & Yazan A.",
    },
    {
        "id": "chartreuse",
        "name": "MULT1COLOR - CHARTREUSE",
        "artists": "nFinite & Yazan A.",
    },
    {
        "id": "indigo",
        "name": "MULT1COLOR - INDIGO",
        "artists": "nFinite & Yazan A.",
    },
    {
        "id": "mandarine",
        "name": "MULT1COLOR - MANDARINE",
        "artists": "nFinite & Yazan A.",
    },
    {
        "id": "ruby",
        "name": "MULT1COLOR - RUBY",
        "artists": "nFinite & Yazan A.",
    },
    {
        "id": "nxt_10",
        "name": "NXT.10",
        "artists": "Cherry",
    },
    {
        "id": "outpace_pc",
        "name": "OUTPACE",
        "artists": "halfprism & Andrew B.",
    },
    {
        "id": "re_define_industry_pc",
        "name": "RE:DEFINE.INDUSTRY",
        "artists": "Andrew B. & nFinite",
    },
    {
        "id": "rediscover2return",
        "name": "REDISCOVER2RETURN ",
        "artists": "Andrew B.",
    },
    {
        "id": "re_genesis",
        "name": "RE:GENESIS",
        "artists": "Cass & Yazan A.",
    },
    {
        "id": "theberlingroup",
        "name": "THEBERLINGROUP",
        "artists": "Sci",
    },
    {
        "id": "then",
        "name": "THEN...",
        "artists": "Yazan A.",
    },
    {
        "id": "thereaper",
        "name": "THEREAPER",
        "artists": "Andrew B.",
    },
    {
        "id": "under_the_waves",
        "name": "UNDER THE WAVES",
        "artists": "Andrew B. & halfprism",
    },
    {
        "id": "underwater_dreams",
        "name": "UNDERWATER DREAMS",
        "artists": "Andrew B.",
    },
]

export const projMIM=[
    {
        "id": "blurredclarity",
        "name": "BLURREDCLARITY",
        "artists": "ty.gpu",
    },
    {
        "id": "calculator",
        "name": "CALCULATOR",
        "artists": "halfprism",
    },
    {
        "id": "chaoticcreation",
        "name": "CHAOTICCREATION",
        "artists": "GAZDUBZ",
    },
    {
        "id": "chartedearth",
        "name": "CHARTEDEARTH",
        "artists": "halfprism",
    },
    {
        "id": "crocus_glory",
        "name": "CROCUS GLORY",
        "artists": "Kamajiy2k & SleepingDream",
    },
    {
        "id": "dimension",
        "name": "DIMENSION",
        "artists": "nFinite",
    },
    {
        "id": "endless_line",
        "name": "ENDLESS LINE",
        "artists": "nFinite",
    },
    {
        "id": "golden_pheasant_on_plum_blossom",
        "name": "Golden Pheasant On Plum Blossom",
        "artists": "Mikyoku",
    },
    {
        "id": "heaven",
        "name": "HEAVEN",
        "artists": "nFinite",
    },
    {
        "id": "hellscape",
        "name": "HELLSCAPE",
        "artists": "Yazan A.",
    },
    {
        "id": "hemoglobin",
        "name": "HEMOGLOBIN",
        "artists": "Jack & halfprism",
    },
    {
        "id": "home_ip_leak_sky_v",
        "name": "home_ip_leak-sky.v",
        "artists": "Ghostmemory & halfprism",
    },
    {
        "id": "industrialfairytale",
        "name": "INDUSTRIALFAIRYTALE",
        "artists": "Jack",
    },
    {
        "id": "intraspect_dawn",
        "name": "INTRASPECT DAWN VER.",
        "artists": "Jack",
    },
    {
        "id": "intraspect_dusk",
        "name": "INTRASPECT DUSK VER.",
        "artists": "Jack",
    },
    {
        "id": "isoul",
        "name": "ISOUL",
        "artists": "Ghostmemory",
    },
    {
        "id": "isvectorheartmobile",
        "name": "ISVECTORHEARTMOBILE",
        "artists": "halfprism",
    },
    {
        "id": "metalheart_is_compact",
        "name": "METALHEART IS COMPACT",
        "artists": "Yazan A.",
    },
    {
        "id": "metalheart_is_compact_dark",
        "name": "METALHEART IS COMPACT DARK",
        "artists": "Yazan A.",
    },
    {
        "id": "metalheart_is_mobile",
        "name": "METALHEART IS MOBILE",
        "artists": "Kamajiy2k, Andrew B. & halfprism",
    },
    {
        "id": "metalheart_is_surreal",
        "name": "METALHEART IS SURREAL",
        "artists": "SleepingDream & halfprism",
    },
    {
        "id": "mhm1",
        "name": "MHM1",
        "artists": "GAZDUBZ & Andrew B.",
    },
    {
        "id": "mhm2",
        "name": "MHM2",
        "artists": "GAZDUBZ & Andrew B.",
    },
    {
        "id": "mhmgazdubz",
        "name": "MHMGAZDUBZ",
        "artists": "GAZDUBZ",
    },
    {
        "id": "nuphory",
        "name": "NUPHORY",
        "artists": "Nuphory",
    },
    {
        "id": "outpace2",
        "name": "OUTPACE2",
        "artists": "halfprism & Andrew B.",
    },
    {
        "id": "realization",
        "name": "REALIZATION",
        "artists": "SleepingDream",
    },
    {
        "id": "realization_alt_1",
        "name": "REALIZATION ALT 1",
        "artists": "SleepingDream",
    },
    {
        "id": "realization_alt_2",
        "name": "REALIZATION ALT 2",
        "artists": "SleepingDream",
    },
    {
        "id": "starstation_alpha",
        "name": "STARSTATION ALPHA",
        "artists": "Jack & Andrew B.",
    },
    {
        "id": "system__core",
        "name": "SYSTEM//CORE",
        "artists": "Wsx",
    },
    {
        "id": "volcanic_ash",
        "name": "VOLCANIC ASH",
        "artists": "Yazan A.",
    },
    {
        "id": "zen_on",
        "name": "ZEN.ON",
        "artists": "Jack",
    },
];

export const projSynthesis=[
    {
        "id": "12_31_21",
        "name": "12 31 21",
        "artists": "Andrew B.",
    },
    {
        "id": "beginning_decryption",
        "name": "BEGINNING DECRYPTION",
        "artists": "Andrew B. & Cass",
    },
    {
        "id": "synthesis",
        "name": "SYNTHESIS",
        "artists": "Andrew B. & Cass",
    },
];

export const projGenerations=[
    {
        "id": "andromeda",
        "name": "ANDROMEDA",
        "artists": "Wsx",
    },
    {
        "id": "aquaeterna",
        "name": "AQUA-ETERNA",
        "artists": "Helvatten",
    },
    {
        "id": "archenemy",
        "name": "ARCHENEMY",
        "artists": "Yazan A. & Andrew B.",
    },
    {
        "id": "avalon",
        "name": "AVALON",
        "artists": "Andrew B.",
    },
    {
        "id": "breach",
        "name": "BREACH",
        "artists": "halfprism",
    },
    {
        "id": "breach_rmx",
        "name": "BREACH.RMX",
        "artists": "halfprism, Andrew B. & Jack",
    },
    {
        "id": "breakpoint",
        "name": "BREAKPOINT",
        "artists": "Yazan A.",
    },
    {
        "id": "captured_in_solitude",
        "name": "CAPTURED IN SOLITUDE",
        "artists": "Andrew B. & halfprism",
    },
    {
        "id": "captured_in_solitude_alt",
        "name": "CAPTURED IN SOLITUDE ALT",
        "artists": "Andrew B. & halfprism",
    },
    {
        "id": "change",
        "name": "CHANGE",
        "artists": "nninety",
    },
    {
        "id": "commandprompt",
        "name": "COMMAND PROMPT",
        "artists": "Sci",
    },
    {
        "id": "cyberlich",
        "name": "CYBERLICH",
        "artists": "Helvatten & Andrew B.",
    },
    {
        "id": "dynamo",
        "name": "DYNAMO",
        "artists": "Yazan A.",
    },
    {
        "id": "dynamo_rmx",
        "name": "DYNAMO.RMX",
        "artists": "Yazan A. & Andrew B.",
    },
    {
        "id": "dynatherm",
        "name": "DYNATHERM",
        "artists": "Yazan A. & Andrew B.",
    },
    {
        "id": "fragmentation",
        "name": "FRAGMENTATION",
        "artists": "Andrew B.",
    },
    {
        "id": "fragmentation_alt",
        "name": "FRAGMENTATION ALT",
        "artists": "Andrew B.",
    },
    {
        "id": "getconnected",
        "name": "GET CONNECTED",
        "artists": "Sci",
    },
    {
        "id": "grungearc",
        "name": "GRUNGEARC",
        "artists": "Andrew B. & Grimm",
    },
    {
        "id": "innatespirit",
        "name": "INNATE.SPIRIT",
        "artists": "Jack & Yazan A.",
    },
    {
        "id": "ndividualize",
        "name": "NDIVIDUALIZE",
        "artists": "Yazan A.",
    },
    {
        "id": "nu_street",
        "name": "NU STREET",
        "artists": "halfprism",
    },
    {
        "id": "origins_of_nature",
        "name": "ORIGINS OF NATURE",
        "artists": "Cherry",
    },
    {
        "id": "platform-iciant",
        "name": "PLATFORM.ICIANT",
        "artists": "Yazan A.",
    },
    {
        "id": "project-captum",
        "name": "PROJECT-CAPTUM",
        "artists": "Andrew B. & Sangrakkorrakkor",
    },
    {
        "id": "protosense",
        "name": "PROTOSENSE",
        "artists": "Helvatten",
    },
    {
        "id": "psychoticstream06",
        "name": "PSYCHOTICSTREAM’06",
        "artists": "Jack",
    },
    {
        "id": "shards_of_anew",
        "name": "SHARDS OF ANEW",
        "artists": "Andrew B. & Wsx",
    },
    {
        "id": "to_the_core",
        "name": "TO THE CORE",
        "artists": "Andrew B. & Yazan A.",
    },
    {
        "id": "vessel",
        "name": "VESSEL",
        "artists": "Cass",
    },
    {
        "id": "vibrance",
        "name": "VIBRANCE",
        "artists": "Wsx",
    },
    {
        "id": "x",
        "name": "X",
        "artists": "nninety",
    },
];

export const projReminisce=[
    {
        "id": "epitaph",
        "name": "EPITAPH",
        "artists": "Cherry & Andrew B.",
    },
    {
        "id": "radiant_purifico_rmx",
        "name": "RADIANT PURIFICO.RMX",
        "artists": "Andrew B. & Grimm",
    },
    {
        "id": "reminisce",
        "name": "REMINISCE",
        "artists": "Andrew B. & Yazan A.",
    },
    {
        "id": "neofunktion",
        "name": "NEOFUNKTION",
        "artists": "Cass & Yazan A.",
    },
    {
        "id": "returner",
        "name": "RETURNER",
        "artists": "Grimm & Andrew B.",
    },
    {
        "id": "stranded_east",
        "name": "STRANDED EAST",
        "artists": "halfprism & nninety",
    },
    {
        "id": "spacewalk",
        "name": "SPACEWALK",
        "artists": "Sangrakkor & Cherry",
    },
    {
        "id": "redirect",
        "name": "REDIRECT",
        "artists": "Grimm & Andrew B.",
    },
    {
        "id": "branch_out",
        "name": "BRANCH OUT",
        "artists": "nninety & halfprism",
    },
    {
        "id": "trapped_in_a_moment",
        "name": "TRAPPED IN A MOMENT",
        "artists": "Sangrakkor & Cherry",
    },
    {
        "id": "reincarnation",
        "name": "REINCARNATION",
        "artists": "Grimm & Andrew B.",
    },
    {
        "id": "memory_os",
        "name": "MEMORY OS",
        "artists": "halfprism & nninety",
    },
    {
        "id": "birthed_from_limbs",
        "name": "BIRTHED FROM LIMBS",
        "artists": "Sangrakkor & Cherry",
    },
    {
        "id": "06823_ghostchasm",
        "name": "0682!:3.GHOSTCHASM",
        "artists": "Cass & Yazan A.",
    },
    {
        "id": "re_genesis",
        "name": "RE:GENESIS",
        "artists": "Cass & Yazan A.",
    },
];

export const projThree=[
    {
        "id": "threebythree",
        "name": "THREEBYTHREE",
        "artists": "Cass & Andrew B.",
    },
    {
        "id": "ncept",
        "name": ".NCEPT",
        "artists": "Yazan A.",
    },
    {
        "id": "violet_augmentation",
        "name": "VIOLET AUGMENTATION",
        "artists": "Sangrakkor & Grimm",
    },
    {
        "id": "empyrean_",
        "name": "EMPYREAN_",
        "artists": "Cass, Sangrakkor & Yazan A.",
    },
    {
        "id": "cenaturi_rush",
        "name": "CENATURI.RUSH",
        "artists": "Sangrakkor, Yazan A. & Cass",
    },
    {
        "id": "2nd_millenary",
        "name": "2ND.MILLENARY",
        "artists": "Sangrakkor, Yazan A. & Cass",
    },
    {
        "id": "tri-star",
        "name": "TRI-STAR",
        "artists": "Cherry, halfprism & Andrew B.",
    },
    {
        "id": "halcyon_futurism",
        "name": "HALCYON FUTURISM",
        "artists": "Cherry, halfprism & Andrew B.",
    },
    {
        "id": "construct",
        "name": "CONSTRUCT",
        "artists": "Cherry, halfprism & Andrew B.",
    },
    {
        "id": "construct_alt",
        "name": "CONSTRUCT ALT",
        "artists": "Cherry, halfprism & Andrew B.",
    },
    {
        "id": "spectra",
        "name": "SPECTRA",
        "artists": "nninety, Helvatten & Varse",
    },
    {
        "id": "aqualust",
        "name": "AQUALUST",
        "artists": "Varse",
    },
    {
        "id": "loaded",
        "name": "LOADED",
        "artists": "nninety, Helvatten & Varse",
    },
];